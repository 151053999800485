
.content-overlay {
    z-index: 1054;
    height: 100vh;
    position: fixed;
    width: calc(100vw - 260px);
    margin-left: 260px;

    @media (max-width: 1200px) {
        width: 100vw;
        margin-left: 0;
    }
    .overlay {
        background-color: rgba(00, 00, 00, 0.4) !important;
    }
}