/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import '_include.scss';

body {
  background: #000;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #13131307;
}

::-webkit-scrollbar-thumb {
  background: #35dbfd;
  border-radius: 10px;
}

.wrapper {
  background-image: url('../images/backgrounds/main-pattern.png');
  background-position: bottom right;
  background-repeat: no-repeat;
}

.main-menu {
  background-image: url('../images/backgrounds/navigation-background.png') !important;
  background-position: bottom !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;

  & .navigation {
    background: none !important;
  }
}

.fit-content {
  width: fit-content;
}

.dark-layout {
  .bs-stepper,
  .card {
    /* custom syncstage border */
    border: double 2px transparent;
    border-radius: $card-border-radius;
    background-image: linear-gradient($theme-dark-card-bg, $theme-dark-card-bg),
      radial-gradient(at top, #00ffea 0%, #00c2ff 17.71%, #004cdf 40.63%, #321497 58.85%, #9800cd 81.25%, #cd00a0 100%) !important;
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
}

.btn-primary {
  color: black !important;
}

.grecaptcha-badge {
  opacity: 0;
}

.app-loader {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #141a1f;
}

#root {
  background-color: #141a1f;
}

.modal-backdrop {
  opacity: 1 !important;
  background-color: rgba(00, 00, 00, 0.6);
}

.projects-select {
  $selectMinWidth: 200px;

  min-width: $selectMinWidth;

  & .select__menu {
    width: max-content;
    min-width: $selectMinWidth;
    max-width: 500px;
  }
}

.navigation {
  a.active {
    background: $primaryGradient !important;
    box-shadow: unset !important;
  }
}

.required::after {
  content: '*';
}

.modal-form-content {
  min-height: 200px;
}

/** reactstrap overrides */
.form-text {
  padding-top: 0.2rem;
  display: inline-block;
}

.form-field {
  margin-bottom: 1rem;
}

.select__menu,
.react-select__menu {
  .select__menu-list,
  .react-select__menu-list {
    .select__option,
    .react-select__option {
      padding: 0.6rem 1.2rem;
    }

    .select__option--is-selected {
      color: black !important;
    }
  }
}

.dropdown-menu {
  // modal has 1050
  z-index: 2050;
}
