@import '../include';

.register-content-wrapper {
  z-index: 1;
  max-width: 1800px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 2rem;

  @media (max-width: 800px) {
    padding: 0;
  }

  @media (max-width: 500px) {
    background-color: $theme-dark-card-bg;
  }

  .register-content {
    height: fit-content;
    width: 60%;
    max-width: 900px;

    .image-wrapper {
      width: 100%;
      display: none;
      text-align: center;
      @media (max-width: 500px) {
        display: block;
      }
    }

    @media (max-width: 800px) {
      width: 90%;
      margin: 0 auto;
    }

    @media (max-width: 500px) {
      width: 100%;
    }

    .bs-stepper {
      @media (min-width: 800px) or (max-width: 500px) {
        background-image: none !important;
        background-color: transparent;
        border: none;
        box-shadow: none !important;
      }
    }

    .register-wizard-header {
      border-bottom: 1px solid $theme-dark-divider !important;
      margin-bottom: 3rem !important;
    }
  }
}
