@import '../include';

.unauthorized-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.unauthorized-wrapper .unauthorized-header {
  padding: 1rem 2rem;
  z-index: 1;

  @media (max-width: 500px) {
    display: none;
  }
}

.unauthorized-wrapper .unauthorized-content {
  flex: 1;
  display: flex;
  width: 100%;
  padding: 0 3rem;

  @media (max-width: 500px) {
    padding: 0;
  }
}

.unauthorized-wrapper .unauthorized-footer {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  border-top: 0.5px solid $theme-dark-divider;
  height: fit-content;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  column-gap: 3rem;
  row-gap: 10px;
  padding: 1.5rem;
  font-weight: 600;
  font-size: 12px;
  a {
    text-transform: uppercase;
  }
  span {
    color: $white;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-end;
    border-top: none;
  }

  @media (max-width: 500px) {
    width: 100%;
    background-color: $theme-dark-card-bg;
  }
}

.unauthorized.gradient-bg {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;

  background: url('../../images/backgrounds/gradient-1.png');
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.unauthorized.pattern-bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;

  background: url('../../images/backgrounds/pattern.png');
  background-repeat: no-repeat;
  background-size: auto 100%;
}
