.skeleton {
    border-radius: 4px;
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(210, 24%, 15%);
    }

    100% {
        background-color: hsl(210, 24%, 20%);;
    }
}