@import '../include';

.login-card-content {
  height: fit-content;
  margin: auto;
  width: 400px;

  @media (max-width: 500px) {
    width: 100%;
    height: 100%;
    .card {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 0;
    }
  }
}
