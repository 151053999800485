@import '../include';

.custom-select {

    &.is-invalid {
      .select__control {
        border-color: var(--bs-danger) !important;
      }
    }
  }
  

.select__menu-portal {
  z-index: 1060 !important; // modal has 1050
}
