//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

@import '_font.scss';

$font-family-sans-serif: 'Josefin Sans', Helvetica, Arial, serif;
$font-family-monospace: 'Josefin Sans', Helvetica, Arial, serif;

$primary: #36dbfd;
$secondary: #82868b;
$primaryGradient: linear-gradient(45deg, #7F01A0 0%, #0149B6 100%);
